import {React, useState, useEffect, useRef} from 'react';
import Meme from "./Meme.jsx";
import { useHotkeys } from 'react-hotkeys-hook'

import "./css/global.css";
import "./css/memes.css";

const DEFAULT = 0;
const log   = console.log;

export default function Memes() {
    const inputr = useRef();
    const [memes, setMemes] = useState([]);
    const [description, setDescription] = useState("");
    const [currentPagingIndex, setCurrentPagingIndex] = useState(DEFAULT);
    const GET_MEMES_BASE = 'https://memes.api.surfizan.xyz/app/api/memes';
    const GET_MEMES = `${GET_MEMES_BASE}?curr=${currentPagingIndex}&desc=${description}`; // TODO dynamic url to the state.

    useEffect(() => {
	    fetch(GET_MEMES, {
            //headers: {'Content-Type': 'application/json'},
            method : "GET",
	    }).then(r => r.json()).then(rjson => {
            const memes_ = rjson.memes;
            setMemes([...memes_]);
            setCurrentPagingIndex(currentPagingIndex+memes_.length);
	    });
    }, ([]));

    /* handling all the keypresses */
    const handle_search_shortcut = () => { inputr.current.focus(); }
    useHotkeys('s', () => handle_search_shortcut(), [])

    const handle_click = () => {
        setCurrentPagingIndex(DEFAULT);
        fetch(GET_MEMES, {
            //headers: {'Content-Type': 'application/json'},
            method : "GET",
	    }).then(r => r.json()).then(rjson => {
            const memes_ = rjson.memes;
            setMemes([...memes_]);
            setCurrentPagingIndex(currentPagingIndex+memes_.length);
	    });

    };

    const handle_load_more = () => {
        fetch(GET_MEMES, {
            //headers: {'Content-Type': 'application/json'},
            method : "GET",
	    }).then(r => r.json()).then(rjson => {
            const memes_ = rjson.memes;
            setMemes([...memes,...memes_]);
            setCurrentPagingIndex(currentPagingIndex+memes_.length);
	    });
    };

    if (memes.length === 0) {
        return (
            <img src='no_memes.jpg' alt='No memes available'/>
        )
    }

    return (
        <>
            <div className='container-search'>
                <input value={description} ref={inputr} placeholder="Type 's' to search" required type="text" onChange={(e) => setDescription(e.target.value)} />
                <a onClick={handle_click}>Search</a>
            </div>
            <div className='container-memes'>
                {memes.map(meme =>
                    <Meme
                        description={meme.description}
                        fetching_url_image={`${GET_MEMES_BASE}/blob/${meme.sha256sum}`}
                        sha256sum={meme.sha256sum}
                    />)
                }
            </div>
            <a onClick={handle_load_more}>Load More</a>
        </>
    )
};
