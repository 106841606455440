import {React} from 'react';

import "./css/global.css";
import "./css/meme.css";

const log = console.log;

export default function Meme({
    description,
    fetching_url_image,
    sha256sum
}) {
    const handle_open_new_tab = () => {
        window.open(fetching_url_image, '_blank', 'noopener,noreferrer');

    };

    return (
        <div /*title='Click To Popup'*/ className='meme-container' key={sha256sum}>
            <img onClick={handle_open_new_tab} title='Click To Open Me In A Sperate Tab' key={sha256sum+"S"} src={fetching_url_image} alt="Someone's meme !"/>
            <p key={sha256sum+"SS"}>`{description}`</p>
            <p key={sha256sum+"SSS"}>ID({sha256sum})</p>
        </div>
    )
}
