export default function Report(){
    return (
        <>
            <h1>Report</h1>
            <p>
                To report, send an brief and concise email at conkretus22@proton.me
            </p>
            <p>Specify the ID of the image (sha256) so I can take it quickly.</p>
            <p>Thanks for the contributing.</p>
        </>
    );
}
