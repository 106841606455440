import { Link } from "react-router-dom";

export default function About() {
    return (
        <>
            <h1>About</h1>
            <p>The purpose of this website is, as the name implies, conserve memes.</p>
            <p>Memes are a very important and fascinating aspect of the cursed modern world.</p>
            <p>Indeed, I do believe that there shall be a space on this big world called the internet dedicated to memes.</p>

            <p>If you find something disturbing do not hesitate to <Link to={"/report"}>report</Link></p>
        </>
    );
}
