import { Link } from "react-router-dom";

export default function Home() {
    return (
        <>
            <h1>
                The meme archive.
            </h1>
            <div class="banner">
                <img src="home_banner.jpg" alt='No memes available'/>
                <br />
                <Link to={"/memes"}>Navigate</Link>
                <br />
                <Link to={"/submit"}>Submit Meme</Link>
                <br />
                <Link to={"/about"}>About</Link>
                <br />
                <Link to={"/report"}>Report</Link>
            </div>
        </>
    );
};