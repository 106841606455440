import {React, useState} from 'react';

const log = console.log;
const MAX_SIZE = 50_000; // bytes
const MAX_SIZE_D = 200; // 200 chars
const URL = /* for local dev */ "http://localhost:3080/app/api/memes/upload";

const process_file = (file) => {
    const size_t = file.size;
    log(size_t);
    return !(size_t > MAX_SIZE);
}
const process_description = (input) => {
    return !(input.length > MAX_SIZE_D);
}

export default function SubmitMeme() {
    const [info, setInfo] = useState("");
    const [files, setFiles] = useState([]);
    const [desc, setDesc] = useState("");

    const handle_submit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        let file     = files[0];

        if (!process_file(file)) {
            setInfo(`ERR, File is too big ! Greater than 50 KB [${file.size / 1_000} KB]`);
        } else if (!process_description(desc)) {
            setInfo(`ERR, Description is too big ! Greater than 200 chars", [${desc.size / 1_000} KB]`);
        } else {
            formData.append("meme", file);
            formData.append("desc", desc);

            fetch(URL, {
                method : "POST",
                body: formData
            }).then(r => r.status).then(status => setInfo(`CODE ${status}`));
        }
    }

    return (
        <>
            <h1>Your Meme</h1>
            <span>{info}</span>
            <div class="container-form">
                <form id="form" onSubmit={(e) => handle_submit(e)}>
                    <textarea
                        id="description" 
                        onChange={e => setDesc(e.target.value)}
                        placeholder="Enter description (Make it keywords so it is easier to search trough)"
                    ></textarea>
                    <br />
                    <input accept="image/*" onChange={e => setFiles(e.target.files)} id="file" type="file"/>
                    <br />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    );
};
