import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home.jsx";
import Memes from "./Components/Memes.jsx";
import SubmitMeme from "./Components/SubmitMeme.jsx";
import About from "./Components/About.jsx";
import Report from "./Components/Report.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/memes" element={<Memes/>}/>
        <Route path="/submit" element={<SubmitMeme/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/report" element={<Report/>}/>
      </Routes>
    </BrowserRouter>
);
